<template>
  <main class="kb-main" id="kb-newcomer">
    <LxpMobileHeader :show-custom-title="true" :show-back="true" title="신입행원 온보딩 공지사항"/>
    <!-- main-content -->
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- notice-list-container -->
      <div class="notice-list-container">
        <!-- list-top-->
        <div class="list-top status">
          <div class="top-column">
            <p class="text text-muted">전체 {{ paging.totalCount }}건</p>
          </div>
        </div>
        <!-- board-list-container-->
        <div class="board-list-container">
          <ul class="board-list">
            <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
              <router-link :to="{name: 'JuniorNoticeView', params: {comNtcSn: item.comNtcSn}}" class="board-link"></router-link>
              <div class="list-content">
                <div class="list-content-title">
                  <h5 class="title">
                    <span class="text text-truncate">{{item.ntcTitle}}</span>
                    <i class="icon-new ms-1"></i>
                  </h5>
                </div>
                <div class="list-meta-content">
                  <span class="text">{{ timestampToDateFormat( item.regDt, 'yyyy-MM-dd')}} 작성</span>
                  <span class="text">{{item.inqCnt}} 조회</span>
                </div>
              </div>
              <div v-if="item.ntcFileAtchYn === 'Y'" class="board-column column-file"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>
            </li>
          </ul>
          <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>
        </div>
        <div v-if="items.length === 0" class="search-result">
          <div class="result-empty">
            <img src="@/assets/lxp/mobile/images/_common/img_empty.png">
            <p class="text">검색결과가 없어요</p>
          </div>
        </div>
        <!-- //board-list-container-->
      </div>
      <!-- //notice-list-container -->
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import CommonPaginationFront from "@/components/CommonPaginationFront";
import {ACT_GET_HELP_NOTICE_LIST} from "@/store/modules/help/help";
import {ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {
  getItems,
  getPaging,
  initPaging,
  lengthCheck,
  initProgressBar,
  timestampToDateFormat,
} from "@/assets/js/util";
import {expsrLocCds} from "@/assets/js/modules/help/help-common";

export default {
  name: "JuniorNoticeMobile",
  components: {
    LxpMobileHeader,
    CommonPaginationFront
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const paging = ref(initPaging(route));

    const items = ref([]);

    const getOnboardingNoticeList = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        expsrLocCd: expsrLocCds.EXPSR_LOC_CD_ON_BOARDING,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const pagingFunc = (pageNo, pageSize) => {
      const query = {pageNo: pageNo};
      if (pageSize !== paging.value.pageSize) {
        query.pageSize = pageSize;
      }
      router.push({query: query});
    };


    getOnboardingNoticeList();

    watch(() => route.query, () => {
      getOnboardingNoticeList();
      initProgressBar();
    });

    return {
      items,
      paging,
      timestampToDateFormat,
      pagingFunc,
    }
  }
}
</script>